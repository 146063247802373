<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
      <div>
    
      </div>
      <HistoryPrivilegeDetail :detail="privilegeDetail" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import HistoryPrivilegeDetail from "@/components/privilege/detail/HistoryPrivilegeDetail.vue";
export default {
  components: {
    Breadcrumb,
    HistoryPrivilegeDetail,
    OtherLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "ประวัติการแลกสิทธิพิเศษ",
          to: "/privilege?tabIndex=1"
        }
      ],
      privilegeDetail: {},
      id: this.$route.params.id,
      isLoading: true
    };
  },
  async created() {
    await this.getHistoryPrivilegeDetail();
  },
  methods: {
    async getHistoryPrivilegeDetail() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/privilege/previlege_detail/${this.id}`
        )
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.privilegeDetail = data.detail[0];
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped></style>
